<template>
  <div class="floor-map-controls">
    <div class="floor-svg-container floor-map position-relative">
      <!-- <svg id="floor_map" width="975" height="535" viewBox="0 0 975 535" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect width="885" height="550" fill="url(#pattern0_4701_200)" id="pointer-none"/> -->
        <svg id="floor_map" width="891" height="595" viewBox="0 0 891 595" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <rect width="891" height="595" fill="url(#pattern0_4701_200)" id="pointer-none"/>

        <g v-for="(floor, key) in variants" :key="key">
          <g v-for="(path, pathKey) in floor.paths">
            <path stroke="#0499B7" :key="`${key}-${pathKey}`" stroke-width="4" :id="`${floor.key}-${pathKey}`" :d="path.path" @click="selectFloor(floor)" fill="#0499B7" :opacity="isPathSelected(path, floor) ? selectedOpacity : path.opacity"
              :fill-opacity="isPathSelected(path, floor) ? selectedFillOpacity : isPathHovered(path) ? hoverFillOpacity : path.fillOpacity" 
              @mouseover="highlightWing(path);" @mouseleave="removehighlight(path)" />
              <b-popover boundary="window" triggers="click hover blur" v-if="!isPathSelected(path, floor) && currentScale === 1"
              :target="`${floor.key}-${pathKey}`" placement="top" custom-class="popover-wrapper">
              {{ floor.configuration }}
            </b-popover>
            <b-popover boundary="window" v-if="showMySelectedPopup && isPathSelected(path, floor) && currentScale === 1" :show="true"
              triggers="" :target="`${floor.key}-${pathKey}`" placement="top" custom-class="popover-wrapper">
              {{ floor.configuration }}
            </b-popover>
          </g>
        </g>
        <defs>
        <pattern id="pattern0_4701_200" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_4701_200" transform="matrix(0.000244522 0 0 0.000366166 -0.00078062 0)"/>
        </pattern>
        <image id="image0_4701_200" width="4096" height="2731" href="@/assets/images/floor-plan.png" />
        </defs>
      </svg>
    </div>
    <div class="compass text-right">
      <img src="@/assets/images/compass.png" alt="" width="84" height="92">
    </div>
    <!-- <div class="svg-zoom-controls" v-if="!isMobile">
      <button class="btn zoom-button" @click="zoom(1)">
        <img src="@/assets/images/zoom-in.svg" height="16" width="16" class="img-fluid" />
      </button>
      <button class="btn zoom-button zoom-reset-button" @click="reset">Reset</button>
      <button class="btn zoom-button" @click="zoom(-1)">
        <img src="@/assets/images/zoom-out.svg" height="16" width="16" class="img-fluid" />
      </button>
    </div> -->
  </div>
</template>
<script>
import HoverImage from '../components/Inventory/HoverImage.vue';
import { masterJson } from '@/assets/scripts/utility.js';
import Panzoom from '@panzoom/panzoom';
export default {
  name: "FloorPlanSvg",
  components: {
    HoverImage,
  },
  data() {
    return {
      isHovered: false,
      showhighlight: true,
      selectedOpacity: 0.8,
      selectedFillOpacity: 0.2,
      hoverData: {},
      hoverOpacity: 0.8,
      hoverFillOpacity: 0.2,
      currentScale: null,
      showMySelectedPopup: false
    }
  },
  mounted() {
    if (!this.isMobile) {
      this.panzoom = Panzoom(document.getElementById('floor_map'), {
        maxScale: 5,
        disablePan: true,
        overflow: "auto"
      });
      this.currentScale = this.panzoom.getScale();
    }
    this.currentScale = 1;
    if (this.selectedTypology && this.selectedTypology.key) {
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  watch: {
    selectedTypology() {
      this.showMySelectedPopup = false;
      setTimeout(() => this.showMySelectedPopup = true, 300);
    }
  },
  methods: {
    selectFloor(floor) {
      this.$store.dispatch('setTypology', floor);
    },
    highlightWing(row) {
      row.opacity = this.selectedOpacity;
      row.fillOpacity = this.selectedFillOpacity;
      this.showhighlight = false
      this.showhighlight = true;
      this.hoverData = row;
    },
    removehighlight(row) {
      row.opacity = 0.01;
      row.fillOpacity = 0.02;
      this.showhighlight = false
      this.showhighlight = true
    },
    zoom(level) {
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn();
      this.currentScale = this.panzoom.getScale();

    },
    reset() {
      this.panzoom = Panzoom(document.getElementById('floor_map'), {
        scale: 1,
        disablePan: true,
        overflow: "auto"
      });
      this.currentScale = this.panzoom.getScale();
    },
    isPathSelected(path, floor) {
      return this.selectedTypology && this.selectedTypology.id === floor.id;
    },
    isPathHovered(path){
      return this.hoverData && this.hoverData.id === path.id;
    }
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedWing() {
      return this.$store.getters.selectedWing;
    },
    isMobile() {
      return window && window.innerWidth <= 991;
    }
  }
}
</script>
<style scoped>

#pointer-none{
  pointer-events: none;
}

.floor-map path,
.floor-map g {
  outline: none;
  cursor: pointer;
}

svg{
  width: 100%;
  height: auto;
}

.floor-svg-container {
  overflow-x: auto;
  width: 100%;
}

.floor-svg-container {
  scrollbar-width: thin;
}

.floor-svg-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.floor-svg-container::-webkit-scrollbar-track {
  background: transparent
}

.floor-svg-container::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 10px;
  width: 8px;
}

.floor-svg-container::-webkit-scrollbar-thumb:hover {
  background: #5d5e5f;
}


.svg-zoom-controls {
  display: flex;
  justify-content: flex-end;

}

.zoom-button {
  background: #1EB4D2;
  padding: 8px;
  color: #ffffff;
  outline: none;
  box-shadow: none;
}

.zoom-button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.zoom-button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.zoom-button.zoom-reset-button {
  padding-left: 17px;
  padding-right: 17px;
  background: rgba(30,180,210, 0.8);
  border-radius: 0;
}

#floor_map {
  width: 100%;
  height: 100%;
  cursor: default !important;
}

.svg-zoom-controls {
  position: absolute;
  right: 0;
  bottom: 20px;
}

.popover.b-popover{
  pointer-events: none;
}

@media (max-width:991px) {

  
  .floor-map-controls,
  .floor-map-section {
    height: 100%;
  }

}
</style>